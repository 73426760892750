.survey {
  padding-bottom: 100px;
}
.survey .arco-textarea.input-textarea {
  padding: 12px;
  border-radius: 4px 0px 0px 0px;
  border: 1px solid var(--border-2, rgb(229, 230, 235));
  background-color: var(--bg-2, rgb(255, 255, 255));
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
}
.survey .arco-form.survey-from .question-group-desc {
  margin: 0;
}
.survey .question-group-desc:last-child:before {
  display: none;
}
.survey .question-group-desc .arco-form-label-item label {
  font-size: 16px;
  font-weight: 500;
}
.survey .question-group-desc .arco-form-layout-vertical > .arco-form-label-item label {
  font-size: 14px;
  font-weight: 500;
}
.survey .arco-divider-horizontal {
  margin-bottom: 40px;
}
.survey .arco-icon[stroke=currentColor] {
  stroke: currentColor;
  fill: none;
  font-size: 24px;
}
.survey .survey-from-button {
  margin-top: 10px;
  font-size: 14px;
  font-weight: 400;
}

.survey-submit-content,
.survey-expired-content {
  height: calc(100vh - 84px);
}

.survey-submit-content {
  padding-top: 200px;
}

.survey-expired-content {
  padding-top: 200px;
}
.survey-expired-content .arco-result-title {
  position: relative;
  top: -40px;
}